import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import Header from '../partials/Header'
import { youtube, reddit } from '../data/social.json'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, fab)

export default function Index() {
  const {
    allMdx: { edges: posts },
  } = useStaticQuery(graphql`
    {
      allMdx(filter: { slug: { glob: "blog/*" } }) {
        edges {
          node {
            id
            slug
            frontmatter {
              title
              date
              path
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Header>
        <p className="leading-tight text-sm">
          I've been losing my hair throughout my twenties.
          <br />I didn't take major action till 2020 at age 28. This is my story.
        </p>
      </Header>

      <div className="container px-4 mx-auto">
        <section className="mb-8 max-w-3xl">
          <p>
            I share my hair loss progress on{' '}
            <a href={youtube} target="_blank">
              YouTube
            </a>{' '}
            and this is where I supplement my videos with writing, photos, and data. You can also
            check my current <a href="/hair-loss-stack">hair loss prevention stack</a>.
          </p>
        </section>
        <aside className="mb-8 p-4 md:p-6 bg-yellow-100 rounded-lg md:inline-block">
          <h2>
            <Link className="no-underline" to="/progress-photos">
              Progress Photos
            </Link>{' '}
            🆕 🆒
          </h2>
          <p>Compare images of my hair loss like in my YouTube videos.</p>
          <Link
            to="/progress-photos"
            className="bg-yellow-600 inline-block py-3 px-4 text-white rounded-lg font-bold no-underline"
          >
            <span>🎞</span> <span className="px-2">Compare</span>{' '}
            <FontAwesomeIcon icon="arrow-right" />
          </Link>
        </aside>
        <section>
          <h2>Recent Posts</h2>
          <ul className="ml-0">
            {posts.map(
              ({
                node: {
                  frontmatter: { date, title, path },
                },
              }) => (
                <li key={path} className="flex">
                  <span className="flex-shrink-0">{date}</span>
                  <span className="px-1 text-gray-400">//</span>
                  <Link to={path}>{title}</Link>
                </li>
              )
            )}
          </ul>
        </section>
      </div>
    </>
  )
}
